import React from "react";
import PropTypes from "prop-types";
import TandCCheckbox from "../../../components/checkbox/TandCCheckbox";
import NewsletterCheckbox from "../../../components/checkbox/NewsletterCheckbox";

// PropTypes
Checkboxes.propTypes = {
  errors: PropTypes.object,
  register: PropTypes.func.isRequired,
};

export default function Checkboxes({ errors, register }) {
  const TnCdata =
    "I agree to Local Surveyors Direct <a href='https://www.localsurveyorsdirect.co.uk/terms-and-conditions-clients-agreement' target='_blank'>terms and conditions</a>";

  return (
    <>
      <TandCCheckbox
        register={register}
        errors={errors}
        checkboxLabel={TnCdata}
      />
      <NewsletterCheckbox register={register} />
    </>
  );
}
