import React from "react";
import PropTypes from "prop-types";
import CheckboxBox from "./CheckboxBox2";
import ControlledCheckbox from "./ControlledCheckbox";

function TandCCheckbox({ errors = {}, register, checkboxLabel }) {
  const termsAndConditionsCheckboxError = errors.termsAndConditions;
  const TnCHTML = checkboxLabel; // Remove the wrapping <span> element

  //console.log( termsAndConditionsCheckboxError  )
  var termsCheckbox = (
    <>
      <ControlledCheckbox
        register={register}
        registerWhat={"termsAndConditions"}
        checkboxID={"termsAndConditions"}
        checkboxLabel={TnCHTML}
      />
    </>
  );

  return (
    <>
      <CheckboxBox
        label={TnCHTML}
        checkboxes={termsCheckbox}
        errors={termsAndConditionsCheckboxError}
      />
    </>
  );
}

TandCCheckbox.propTypes = {
  errors: PropTypes.object,
  register: PropTypes.func.isRequired,
  checkboxLabel: PropTypes.string.isRequired,
};

export default TandCCheckbox;
